<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'Accounting' && isForm" cols="4">
      <v-form ref="accountingInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client Name</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="recordData.Client.Individual"
                  outlined
                  :items="clients"
                  dense
                  :disabled="true"
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.recordData.clientId, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.recordData.clientId.$touch"
                  @blur="$v.recordData.clientId.$touch"
                />
              </v-col>
            </v-row> -->
            <v-row class="px-10">
              <v-col cols="4">
                <p>Financial Year</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.finanicialYear"
                  color="red darken-4"
                  outlined
                  dense
                  label="Financial Year"
                  :error-messages="requiredError($v.recordData.finanicialYear,'Financial Year')"
                  @change="$v.recordData.finanicialYear.$touch"
                  @blur="$v.recordData.finanicialYear.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Accounting Fees</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.accountingFees"
                  color="red darken-4"
                  outlined
                  dense
                  label="Accounting Fees"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>VAT Compliance Fee</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.vatComplianceFees"
                  color="red darken-4"
                  outlined
                  dense
                  label="VAT Compliance Fee"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Tax Compliance Fee</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.taxComplianceFees"
                  color="red darken-4"
                  outlined
                  dense
                  label="Tax Compliance Fee"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Concluded</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox v-model="recordData.concluded" />
                </div>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Audit</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox v-model="recordData.audit" />
                </div>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Audit Fee</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.auditFees"
                  color="red darken-4"
                  outlined
                  dense
                  label="Audit Fee"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Paid/Unpaid</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox v-model="recordData.paidUnpaid" />
                </div>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'EditAccountingDataForm',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      recordData: {
        clientId: { required },
        finanicialYear: { required },
        concluded: { required },
        audit: { required },
        paidUnpaid: { required },
      },
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    recordData: {
      type: Object,
      default: () => {},
    },
    editClientid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      select: {
        clientId: 0,
        clientName: '',
      },
      clientId: '',
      finanicialYear: '',
      accountingFees: '',
      vatComplianceFees: '',
      taxComplianceFees: '',
      concluded: false,
      audit: false,
      auditFees: '',
      paidUnpaid: false,
      clientLoading: false,
      search: '',
      clients: [],
      loading: false,
      checkUpdate: false,
    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
    value(value) {
      this.setValue(value)
    },
  },
  created() {
    this.getAllClients()
  },
  updated() {
    if (!this.checkUpdate) {
      this.select.clientId = 0
      this.select.clientName = ''
      this.select.clientId = this.recordData?.Client?.Individual?.clientId
      this.select.clientName = this.recordData?.Client?.Individual?.clientName

      if (this.select?.clientName !== '') {
        this.checkUpdate = true
      }
    }
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Update Account',
          message: 'Are you sure to update accounting data?',
          confirmAction: () => this.updateAccountingData(),
        })
      }
    },
    async updateAccountingData() {
      const payload = {
        clientId: this.recordData.Client.Individual.clientId,
        accountingId: this.recordData.accountingId,
        finanicialYear: this.recordData.finanicialYear,
        accountingFees: this.recordData.accountingFees,
        vatComplianceFees: this.recordData.vatComplianceFees,
        taxComplianceFees: this.recordData.taxComplianceFees,
        concluded: this.recordData.concluded,
        audit: this.recordData.audit,
        auditFees: this.recordData.auditFees,
        paidUnpaid: this.recordData.paidUnpaid,
      }
      this.loading = true
      try {
        const data = await api.updateAccountingData({ payload })
        this.loading = false
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.checkUpdate = false
        this.select = {}
        this.$emit('refreshAccounting')
        this.finanicialYear = ''
        this.accountingFees = ''
        this.vatComplianceFees = ''
        this.taxComplianceFees = ''
        this.concluded = false
        this.audit = false
        this.auditFees = ''
        this.paidUnpaid = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.accountingInfoForm.reset()
      this.checkUpdate = false
      this.select = {}
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
  },
}
</script>

<style></style>
