var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scroll-x-reverse-transition',[(_vm.tabName === 'VatRecaps' && _vm.isForm)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-form',{ref:"vatRecapInfoForm"},[_c('v-card',{staticClass:"pa-5",attrs:{"flat":"","outlined":""}},[(_vm.loading)?_c('SelfBuildingSpinner'):_c('div',[_c('v-row',{staticClass:"px-10",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("From")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"From Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.fromDate, 'From date')},on:{"change":_vm.$v.fromDate.$touch,"blur":_vm.$v.fromDate.$touch},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}],null,false,2376140034),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("To")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"To Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.toDate, 'To date')},on:{"change":_vm.$v.toDate.$touch,"blur":_vm.$v.toDate.$touch},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}],null,false,963597282),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("Due Date")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"Due Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.dueDate, 'Due date')},on:{"change":_vm.$v.dueDate.$touch,"blur":_vm.$v.dueDate.$touch},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}},'v-text-field',attrs,false),on))]}}],null,false,3253834018),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("Submitted")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"checkbox-margin"},[_c('v-checkbox',{model:{value:(_vm.submitted),callback:function ($$v) {_vm.submitted=$$v},expression:"submitted"}})],1)])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"text-center",attrs:{"color":"red lighten-2","dark":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"justify-self-center",attrs:{"color":"teal lighten-1","dark":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }