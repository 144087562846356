<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'Employee' && isForm" cols="4">
      <v-form ref="accountingEmployeeInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client ID</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="clientID"
                  outlined
                  :items="clients"
                  dense
                  :disabled="true"
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.clientID, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.clientID.$touch"
                  @blur="$v.clientID.$touch"
                />
              </v-col>
            </v-row> -->
            <v-row class="px-10">
              <v-col cols="4">
                <p>Employee</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="employee"
                  outlined
                  dense
                  color="red darken-4"
                  label="Employee"
                  :error-messages="requiredError($v.employee, 'Employee')"
                  @change="$v.employee.$touch"
                  @blur="$v.employee.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>ID Card No</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="idCardNo"
                  outlined
                  dense
                  color="red darken-4"
                  label="ID Card No"
                  :error-messages="requiredError($v.idCardNo, 'ID Card No')"
                  @change="$v.idCardNo.$touch"
                  @blur="$v.idCardNo.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Tax Number</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="taxNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="Tax Number"
                  :error-messages="requiredError($v.taxNumber, 'Tax Number')"
                  @change="$v.taxNumber.$touch"
                  @blur="$v.taxNumber.$touch"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>SSN</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="socialSecurityNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="SSN"
                  :error-messages="
                    requiredError($v.socialSecurityNumber, 'SSN')
                  "
                  @change="$v.socialSecurityNumber.$touch"
                  @blur="$v.socialSecurityNumber.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Basic Wage</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="basicWage"
                  outlined
                  dense
                  color="red darken-4"
                  label="Basic Wage"
                  :error-messages="requiredError($v.basicWage, 'Basic Wage')"
                  @change="$v.basicWage.$touch"
                  @blur="$v.basicWage.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'AccountingEmployeeDataForm',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      clientID: { required },
      employee: { required },
      idCardNo: { required },
      taxNumber: { required },
      socialSecurityNumber: { required },
      basicWage: { required },
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    clientId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      employee: '',
      clientID: this.clientId,
      idCardNo: null,
      taxNumber: null,
      socialSecurityNumber: null,
      basicWage: null,
      clientLoading: false,
      loading: false,
      search: '',
      clients: [],
    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
  },
  created() {
    this.getAllClients()
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Create Account employee',
          message: 'Are you sure to create account employee data?',
          confirmAction: () => this.saveAccountingEmployeeData(),
        })
      }
    },
    async saveAccountingEmployeeData() {
      const payload = {
        clientId: this.clientID,
        employee: this.employee,
        idCardNo: this.idCardNo,
        taxNumber: this.taxNumber,
        socialSecurityNumber: this.socialSecurityNumber,
        basicWage: this.basicWage,
      }
      this.loading = true
      try {
        const data = await api.createAccountEmployee({ payload })
        this.loading = false
        this.$refs.accountingEmployeeInfoForm.reset()
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.$emit('refreshAccounting')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.accountingEmployeeInfoForm.reset()
      this.$refs.accountingEmployeeInfoForm.resetValidation()
      this.clientID = this.clientId;
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
  },
}
</script>

<style></style>
