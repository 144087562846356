<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'VatAccountingTax' && isForm" cols="4">
      <v-form ref="accountingTaxForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client Name</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="clientID"
                  outlined
                  :items="clients"
                  dense
                  :loading="clientLoading"
                  hide-no-data
                  :disabled="true"
                  :error-messages="requiredError($v.clientID, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.clientID.$touch"
                  @blur="$v.clientID.$touch"
                />
              </v-col>
            </v-row> -->
            <v-row class="px-10">
              <v-col cols="4">
                <p>Financial Year</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="finanicialYear"
                  color="red darken-4"
                  outlined
                  dense
                  label="Finanicial Year"
                  :error-messages="
                    requiredError($v.finanicialYear, 'finanicialYear')
                  "
                  @change="$v.finanicialYear.$touch"
                  @blur="$v.finanicialYear.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Year Of Assessment </p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="yearOfAssessment"
                  color="red darken-4"
                  outlined
                  dense
                  label="Year Of Assessment"
                  :error-messages="
                    requiredError($v.yearOfAssessment, 'Year Of Assessment')
                  "
                  @change="$v.yearOfAssessment.$touch"
                  @blur="$v.yearOfAssessment.$touch"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Submit Return</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox v-model="submitReturn" />
                </div>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Refund Eligibility</p>
              </v-col>
              <v-col cols="8">
                <v-select
                  v-model="refundEligibilitySelect"
                  :items="refundEligibilityOptions"
                  item-text="label"
                  item-value="value"
                  label="Select"
                  outlined
                  return-object
                  single-line
                ></v-select>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Refund Submission</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox v-model="refundSubmission" />
                </div>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Refund Value</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="refundValue"
                  color="red darken-4"
                  outlined
                  dense
                  label="Refund Value"
                  :error-messages="
                    requiredError($v.refundValue, 'Refund Value')
                  "
                  @change="$v.refundValue.$touch"
                  @blur="$v.refundValue.$touch"
                />
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'TaxDataForm',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      clientID: { required },
      finanicialYear: { required },
      yearOfAssessment: { required },
      refundValue: { required },
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    clientId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      clientID: this.clientId,
      finanicialYear: '',
      yearOfAssessment: '',
      submitReturn: false,
      refundEligibility: '',
      refundSubmission: false,
      refundValue: '',
      refundEligibilityOptions: [
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' },
      ],
      refundEligibilitySelect: { label: 'Yes', value: 'yes' },
      clientLoading: false,
      search: '',
      clients: [],
      loading: false,
    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
  },
  created() {
    this.getAllClients()
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },

    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Create Tax ',
          message: 'Are you sure to create Tax data?',
          confirmAction: () => this.saveAccountingData(),
        })
      }
    },
    async saveAccountingData() {
      const payload = {
        clientId: this.clientID,
        finanicialYear: this.finanicialYear,
        yearOfAssessment: this.yearOfAssessment,
        submitReturn: this.submitReturn,
        refundEligibility: this.refundEligibilitySelect.value,
        refundSubmission: this.refundSubmission,
        refundValue: this.refundValue,
      }
      this.loading = true
      try {
        const data = await api.createTaxData({ payload })
        this.loading = false
        this.$refs.accountingTaxForm.reset()
        this.finanicialYear = ''
        this.yearOfAssessment = ''
        this.submitReturn = false
        this.refundEligibility = ''
        this.refundSubmission = false
        this.refundValue = ''
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.$emit('refreshAccounting')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.accountingTaxForm.reset()
      this.$refs.accountingTaxForm.resetValidation()
      this.finanicialYear = ''
      this.yearOfAssessment = ''
      this.submitReturn = false
      this.refundEligibility = ''
      this.refundSubmission = false
      this.refundValue = ''
      this.clientID = this.clientId;
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
  },
}
</script>

<style></style>
