<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'VatRecaps' && isForm" cols="4">
      <v-form ref="vatRecapInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client Name</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="clientID"
                  outlined
                  :items="clients"
                  dense
                  :disabled="true"
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.clientID, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.clientID.$touch"
                  @blur="$v.clientID.$touch"
                />
              </v-col>
            </v-row> -->

            <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>From</p></v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="fromDate"
                      outlined
                      dense
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.fromDate, 'From date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.fromDate.$touch"
                      @blur="$v.fromDate.$touch"
                    ></v-text-field>
                  </template>
                   <v-date-picker v-model="fromDate" no-title
                        scrollable @input="menu = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>To</p>
              </v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="toDate"
                      outlined
                      dense
                      label="To Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.toDate, 'To date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.toDate.$touch"
                      @blur="$v.toDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="toDate" no-title
                        scrollable @input="menu1 = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Due Date</p>
              </v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dueDate"
                      outlined
                      dense
                      label="Due Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.dueDate, 'Due date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.dueDate.$touch"
                      @blur="$v.dueDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="dueDate" no-title
                        scrollable @input="menu2 = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Submitted</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox
                        v-model="submitted"
                      />
                </div>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>

          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'VatRecapDataForm',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      clientID: { required },
      fromDate: { required },
        toDate: {required},
      dueDate: {required}
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    clientId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      clientID: this.clientId,
      fromDate: '',
      toDate: '',
      dueDate: '',
      submitted: false,
      menu: false,
      menu1: false,
      menu2: false,
      clientLoading: false,
      search: '',
      clients: [],
      loading: false
    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
  },
  created() {
    this.getAllClients();
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },

    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Create VAT Recap',
          message: 'Are you sure to create VAT Recap data?',
          confirmAction: () => this.saveAccountingData(),
        })
      }
    },
    async saveAccountingData() {
      const payload = {
        AccountingVatRecapsId: this.AccountingVatRecapsId,
        clientId: this.clientID,
        dueDate: this.dueDate,
        fromDate: this.fromDate,
        submitted: this.submitted,
        toDate: this.toDate
      }
      this.loading = true
      try {
        const data = await api.createVatRecapData({ payload })
        this.loading = false
        this.$refs.vatRecapInfoForm.reset()
        this.fromDate = '';
        this.toDate = '';
        this.dueDate = '';
        this.submitted = false;
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.$emit('refreshAccounting')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.vatRecapInfoForm.reset()
      this.$refs.vatRecapInfoForm.resetValidation()
      this.fromDate = '';
      this.toDate = '';
      this.dueDate = '';
      this.submitted = false;
      this.clientID = this.clientId;
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    }
  },
}
</script>

<style></style>
