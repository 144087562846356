<template>
  <Layout>
    <v-card class="elevation-0" outlined>
      <v-card-title>
        <span class="text-h5">Accounting Data</span>
        <v-spacer grow></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="pa-0 fill-height">
          <component 
          :is="editComponent" 
          :accounting-id="accountingId"
          :client-id="clientId"
          :data-id="itemData" />
        </v-container>
      </v-card-text>
    </v-card>
  </Layout>
</template>
<script>
import EditAccountingData from '../../components/Accounting/EditAccountingData.vue'
import Layout from '@layouts/main'
import { 
  EventBusEvents,ClientTypes } from '@src/constants/index.js'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
const INACTIVE_USER_TIME_THRESHOLD = 10 * 60 * 1000 // 10 minutes
const USER_ACTIVITY_THROTTLER_TIME = 5000 // 5s
const FIVE_SECONDS = 5000 // 5 seconds

export default {
  name: 'EditAccounting',
  components: {
    Layout,
    EditAccountingData
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    lockId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      pollingId: '',
      pollingInterval: null,
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null
    }
  },
  computed: {
    editComponent() {
      return this.item.clientType === ClientTypes.INDIVIDUAL
        ? 'EditAccountingData'
        : 'EditAccountingData'
    },
    accountingId() {
      return this.item && this.item
    },
     clientId() {
      return this.item && this.item.clientId
    },
    itemData() {
      return this.item && this.item.clientType
    }
  },
  mounted() {
    this.pollingId = this.lockId

    this.pollingInterval = setInterval(() => {
    }, FIVE_SECONDS)
    window.addEventListener('mousemove', this.userActivityThrottler)
    window.addEventListener('scroll', this.userActivityThrottler)
    window.addEventListener('keydown', this.userActivityThrottler)
    window.addEventListener('resize', this.userActivityThrottler)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.userActivityThrottler)
    window.removeEventListener('scroll', this.userActivityThrottler)
    window.removeEventListener('keydown', this.userActivityThrottler)
    window.removeEventListener('resize', this.userActivityThrottler)

    if (this.userActivityThrottlerTimeout)
      clearTimeout(this.userActivityThrottlerTimeout)
    if (this.userActivityTimeout) clearTimeout(this.userActivityTimeout)

    if (this.pollingInterval) clearInterval(this.pollingInterval)
  },
  methods: {
    async refreshLock() {
      try {
        this.pollingId = await api.lockEditClientRefresh({
          clientId: this.clientId,
          previousLockId: this.pollingId,
        })
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
        this.$router.push({ name: 'accounting' })
      }
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()

          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, USER_ACTIVITY_THROTTLER_TIME)
      }
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)
      this.userActivityTimeout = setTimeout(() => {
        clearInterval(this.pollingInterval)
        this.$router.push({ name: 'accounting' })
      }, INACTIVE_USER_TIME_THRESHOLD)
    },
  },
}
</script>
<style></style>
