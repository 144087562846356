var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-scroll-x-reverse-transition',[(_vm.tabName === 'VatRecaps' && _vm.isForm)?_c('v-col',{attrs:{"cols":"4"}},[_c('v-form',{ref:"vatReturnInfoForm"},[_c('v-card',{staticClass:"pa-5",attrs:{"flat":"","outlined":""}},[(_vm.loading)?_c('SelfBuildingSpinner'):_c('div',[_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("From")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"From Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.recordData.fromDate, 'From date')},on:{"change":_vm.$v.recordData.fromDate.$touch,"blur":_vm.$v.recordData.fromDate.$touch},model:{value:(_vm.recordData.fromDate),callback:function ($$v) {_vm.$set(_vm.recordData, "fromDate", $$v)},expression:"recordData.fromDate"}},'v-text-field',attrs,false),on))]}}],null,false,1354685014),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.recordData.fromDate),callback:function ($$v) {_vm.$set(_vm.recordData, "fromDate", $$v)},expression:"recordData.fromDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("To")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"From Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.recordData.toDate, 'To date')},on:{"change":_vm.$v.recordData.toDate.$touch,"blur":_vm.$v.recordData.toDate.$touch},model:{value:(_vm.recordData.toDate),callback:function ($$v) {_vm.$set(_vm.recordData, "toDate", $$v)},expression:"recordData.toDate"}},'v-text-field',attrs,false),on))]}}],null,false,2830956379),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.recordData.toDate),callback:function ($$v) {_vm.$set(_vm.recordData, "toDate", $$v)},expression:"recordData.toDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("Due Date")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","dense":"","label":"From Date","prepend-icon":"mdi-calendar","readonly":"","error-messages":_vm.requiredError(_vm.$v.recordData.dueDate, 'Due date')},on:{"change":_vm.$v.recordData.dueDate.$touch,"blur":_vm.$v.recordData.dueDate.$touch},model:{value:(_vm.recordData.dueDate),callback:function ($$v) {_vm.$set(_vm.recordData, "dueDate", $$v)},expression:"recordData.dueDate"}},'v-text-field',attrs,false),on))]}}],null,false,2457889876),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.recordData.dueDate),callback:function ($$v) {_vm.$set(_vm.recordData, "dueDate", $$v)},expression:"recordData.dueDate"}})],1)],1)],1),_c('v-row',{staticClass:"px-10"},[_c('v-col',{attrs:{"cols":"4"}},[_c('p',[_vm._v("Submitted")])]),_c('v-col',{attrs:{"cols":"8"}},[_c('div',{staticClass:"checkbox-margin"},[_c('v-checkbox',{model:{value:(_vm.recordData.submitted),callback:function ($$v) {_vm.$set(_vm.recordData, "submitted", $$v)},expression:"recordData.submitted"}})],1)])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"text-center",attrs:{"color":"red lighten-2","dark":""},on:{"click":_vm.close}},[_vm._v("Cancel")])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"justify-self-center",attrs:{"color":"teal lighten-1","dark":""},on:{"click":_vm.save}},[_vm._v("Save")])],1)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }