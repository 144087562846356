<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'Employee' && isForm" cols="4">
      <v-form ref="accountingEmployeeInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client Name</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="recordData.Client.Individual"
                  outlined
                  :items="clients"
                  dense
                  :disabled="true"
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.recordData.clientId, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.recordData.clientId.$touch"
                  @blur="$v.recordData.clientId.$touch"
                />
              </v-col>
            </v-row> -->
            <v-row class="px-10">
              <v-col cols="4">
                <p>Employee</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.employee"
                  outlined
                  dense
                  color="red darken-4"
                  label="Employee"
                  :error-messages="requiredError($v.recordData.employee, 'Employee')"
                  @change="$v.recordData.employee.$touch"
                  @blur="$v.recordData.employee.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>ID Card No</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.idCardNo"
                  outlined
                  dense
                  color="red darken-4"
                  label="ID Card No"
                  :error-messages="requiredError($v.recordData.idCardNo, 'ID Card No')"
                  @change="$v.recordData.idCardNo.$touch"
                  @blur="$v.recordData.idCardNo.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Tax Number</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.taxNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="Tax Number"
                  :error-messages="requiredError($v.recordData.taxNumber, 'Tax Number')"
                  @change="$v.recordData.taxNumber.$touch"
                  @blur="$v.recordData.taxNumber.$touch"
                />
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>SSN</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.socialSecurityNumber"
                  outlined
                  dense
                  color="red darken-4"
                  label="SSN"
                  :error-messages="
                  requiredError(
                    $v.recordData.socialSecurityNumber,'SSN')"
                  @change="$v.recordData.socialSecurityNumber.$touch"
                  @blur="$v.recordData.socialSecurityNumber.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="px-10">
              <v-col cols="4">
                <p>Basic Wage</p>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="recordData.basicWage"
                  outlined
                  dense
                  color="red darken-4"
                  label="Basic Wage"
                  :error-messages="requiredError($v.recordData.basicWage, 'Basic Wage')"
                  @change="$v.recordData.basicWage.$touch"
                  @blur="$v.recordData.basicWage.$touch"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'EditAccountingEmployeeData',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      recordData: {
        clientId: { required },
        employee: { required },
        idCardNo: { required },
        taxNumber: { required },
        socialSecurityNumber: { required },
        basicWage: { required },
      }
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    recordData: {
      type: Object,
      default: () => {},
    },
    editClientid: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      select: {
        clientId: 0,
        name: ''
      },
      clientId: '',
      employee: '',
      idCardNo: null,
      taxNumber: null,
      socialSecurityNumber: null,
      basicWage: null,
      accountingEmployeeId: null,
      clientLoading: false,
      search: '',
      clients: [],
      loading: false,
      checkUpdate: false

    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1 && val !== this.select.name) {
        this.fetchClients({ name: val })
      }
    },
    value(value) {
      this.setValue(value);
    },
  },
  created() {
    this.getAllClients();
  },
  updated() {
    if (!this.checkUpdate) {
      this.select.clientId = 0
      this.select.clientName = ''
      this.select.clientId = this.recordData?.Client?.Individual?.clientId;
      this.select.clientName = this.recordData?.Client?.Individual?.clientName;

      if (this.select?.clientName !== '') {
        this.checkUpdate = true;
      }
    }
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Update Account employee',
          message: 'Are you sure to update account employee data?',
          confirmAction: () => this.updateAccountingData(),
        })
      }
    },
    async updateAccountingData() {
      const payload = {
        accountingEmployeeId: this.recordData.accountingEmployeeId,
        clientId: this.recordData.Client.Individual.clientId,
        employee: this.recordData.employee,
        idCardNo: this.recordData.idCardNo,
        taxNumber: this.recordData.taxNumber,
        socialSecurityNumber: this.recordData.socialSecurityNumber,
        basicWage: this.recordData.basicWage,
      }
      this.loading = true
      try {
        const data = await api.updateAccountEmployee({ payload })
        this.loading = false
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.checkUpdate = false;
        this.select = {};
        this.$emit('refreshAccounting')
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.accountingEmployeeInfoForm.reset()
      this.checkUpdate = false;
      this.select = {};
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },
  },
}
</script>

<style></style>
