<template>
  <v-scroll-x-reverse-transition>
    <v-col v-if="tabName === 'VatRecaps' && isForm" cols="4">
      <v-form ref="vatReturnInfoForm">
        <v-card flat outlined class="pa-5">
          <SelfBuildingSpinner v-if="loading" />
          <div v-else>
            <!-- <v-row class="px-10" align="center" justify="center">
              <v-col cols="4"><p>Client Name</p></v-col>
              <v-col cols="8">
                <v-select
                  v-model="recordData.Client.Individual"
                  outlined
                  :items="clients"
                  dense
                  :disabled="true"
                  :loading="clientLoading"
                  hide-no-data
                  :error-messages="requiredError($v.recordData.clientId, 'Client Name')"
                  item-text="clientName"
                  item-value="clientId"
                  label="Client"
                  return-object
                  @change="$v.recordData.clientId.$touch"
                  @blur="$v.recordData.clientId.$touch"
                />
              </v-col> 
            </v-row>-->
            <v-row class="px-10">
              <v-col cols="4">
                <p>From</p>
              </v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recordData.fromDate"
                      outlined
                      dense
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.recordData.fromDate, 'From date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.recordData.fromDate.$touch"
                      @blur="$v.recordData.fromDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="recordData.fromDate" no-title
                    scrollable @input="menu = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>To</p>
              </v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recordData.toDate"
                      outlined
                      dense
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.recordData.toDate, 'To date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.recordData.toDate.$touch"
                      @blur="$v.recordData.toDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="recordData.toDate" no-title
                    scrollable @input="menu1 = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Due Date</p>
              </v-col>
              <v-col cols="8">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="recordData.dueDate"
                      outlined
                      dense
                      label="From Date"
                      prepend-icon="mdi-calendar"
                      readonly
                      :error-messages="requiredError($v.recordData.dueDate, 'Due date')"
                      v-bind="attrs"
                      v-on="on"
                      @change="$v.recordData.dueDate.$touch"
                      @blur="$v.recordData.dueDate.$touch"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="recordData.dueDate" no-title
                    scrollable @input="menu2 = false" >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>

            <v-row class="px-10">
              <v-col cols="4">
                <p>Submitted</p>
              </v-col>
              <v-col cols="8">
                <div class="checkbox-margin">
                  <v-checkbox 
                  v-model="recordData.submitted" 
                  />
                </div>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="3">
                <v-btn
                  color="red lighten-2"
                  class="text-center"
                  dark
                  @click="close"
                  >Cancel</v-btn
                >
              </v-col>
              <v-col cols="3">
                <v-btn
                  color="teal lighten-1"
                  class="justify-self-center"
                  dark
                  @click="save"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-form>
    </v-col>
  </v-scroll-x-reverse-transition>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import { validateError } from '@utils/validateFields'
import EventBus from '@utils/EventBus'
import { EventBusEvents } from '@src/constants/index.js'
import api from '@src/api/index.js'

export default {
  name: 'EditVatRecapData',
  components: {
    SelfBuildingSpinner,
  },
  mixins: [validationMixin],
  validations() {
    return {
      recordData: {
        clientId: { required },
        fromDate: {required},
        toDate: {required},
        dueDate: {required}
      }
    }
  },
  props: {
    isForm: {
      type: Boolean,
      default: false,
    },
    tabName: {
      type: String,
      default: '',
    },
    recordData: {
      type: Object,
      default: () => {},
    },
    editClientid: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      select: {
        clientId: 0,
        name: ''
      },
      clientId: '',
      clientLoading: false,
      search: '',
      clients: [],
      loading: false,
      menu: false,
      menu1: false,
      menu2: false,
      checkUpdate: false
    }
  },
  watch: {
    search(val) {
      if (this.loading) return
      if (typeof val === 'string' && val.length > 1) {
        this.fetchClients({ name: val })
      }
    },
    value(value) {
      this.setValue(value);
    },
  },
  created() {
    this.getAllClients();
  },
  updated() {
    if (!this.checkUpdate) {
      this.select.clientId = 0
      this.select.clientName = ''
      this.select.clientId = this.recordData?.Client?.Individual?.clientId;
      this.select.clientName = this.recordData?.Client?.Individual?.clientName;

      if (this.select?.clientName !== '') {
        this.checkUpdate = true;
      }
    }
  },
  methods: {
    async getAllClients() {
      this.loading = true
      try {
        const data = await api.getAllClientsNameId()
        data.clients.forEach((element) => {
          if (element) {
            this.clients.push({
              clientId: element.clientId,
              clientName: element.clientName
                ? element.clientName
                : element.companyName,
            })
          }
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        EventBus.$emit(EventBusEvents.OPEN_MODAL, {
          title: 'Update VAT Recap',
          message: 'Are you sure to update VAT Recap data?',
          confirmAction: () => this.updateVatRecapData(),
        })
      }
    },
    async updateVatRecapData() {
      const payload = {
        clientId: this.recordData.Client.Individual.clientId,
        accountingVatRecapsId: this.recordData.accountingVatRecapsId,
        fromDate: this.recordData.fromDate,
        toDate: this.recordData.toDate,
        dueDate: this.recordData.dueDate,
        submitted: this.recordData.submitted
      }
      this.loading = true
      try {
        const data = await api.updateVatRecapData({ payload })
        this.loading = false
        setTimeout(() => this.close(), 0)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.checkUpdate = false;
        this.select = {};
        this.$emit('refreshAccounting')
        this.fromDate = '';
        this.toDate = '';
        this.dueDate = '';
        this.submitted = false;
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    close() {
      this.$refs.vatReturnInfoForm.reset()
      this.checkUpdate = false;
      this.select = {};
      this.fromDate = '';
      this.toDate = '';
      this.dueDate = '';
      this.submitted = false;
      this.$emit('closeTabForm')
    },
    async fetchClients({ name }) {
      this.clientLoading = true
      try {
        const clients = await api.getClientsByName({ name })
        this.clients = clients
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.clientLoading = false
      }
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    }
  },
}
</script>

<style></style>
