<template>
  <!-- <Layout> -->
  <v-row class="px-10">
    <v-col cols="12">
      <v-row class="justify-end pb-2">
        <v-btn @click="save" color="teal lighten-1" outlined depressed dark>
          <span>Save new details</span>
        </v-btn>

        <v-btn
          @click="resetAll"
          color="orange lighten-1"
          depressed
          dark
          class="ml-2"
        >
          <span class="white--text">Back To Accounting</span>
        </v-btn>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-form ref="editClientForm">
              <SelfBuildingSpinner v-if="formLoading" />
              <div v-else>
                <v-row>
                  <v-col cols="12">
                    <v-toolbar flat color="white" class="client-size">
                      <v-toolbar-title
                        ><b>{{ initialValues.clientName }}</b></v-toolbar-title
                      >
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <p class="pt-4" style="color: #b71c1c; opacity: 0.6"
                        >Remember to save your changes before leave the page.</p
                      >
                      <div class="flex-grow-1"></div>
                    </v-toolbar>
                  </v-col>
                </v-row>
                <v-row class="px-6">
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="2">
                        <p
                          >Status: <b>{{ initialValues.status }}</b></p
                        >
                      </v-col>
                      <v-col cols="1">
                        <p>PE Number:</p>
                      </v-col>
                      <v-col cols="2" class="pl-0 pr-6">
                        <v-text-field
                          class="select-style"
                          dense
                          placeholder="Add PE Number"
                          outlined
                          single-line
                          v-model="initialValues.peNumber"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <p
                          >Reg. Number:
                          <b>{{ initialValues.registrationNumber }}</b></p
                        >
                      </v-col>
                      <v-col cols="3">
                        <p
                          >VAT Number: <b>{{ initialValues.vatNumber }}</b></p
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row class="px-6">
                  <v-col cols="2">
                    <p
                      >Company Name: <b>{{ initialValues.clientName }}</b></p
                    >
                  </v-col>
                  <v-col cols="1">
                    <p>ETC Number:</p>
                  </v-col>
                  <v-col cols="2" class="pl-0 pr-6">
                    <v-text-field
                      class="select-style"
                      dense
                      placeholder="Add ETC Number"
                      outlined
                      single-line
                      v-model="initialValues.etcNumber"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <p
                      >Incorporation Date:
                      <b>{{ initialValues.incorporationDate }}</b></p
                    >
                  </v-col>
                  <v-col cols="1">
                    <p>VAT Return Period:</p>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      class="select-style"
                      dense
                      outlined
                      single-line
                      v-model="vatReturnPeriodData"
                      :items="vatReturnPeriodOptions"
                      item-text="label"
                      item-value="value"
                      @change="getVatValue(vatReturnPeriodData)"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row class="px-6">
                  <v-col cols="2">
                    <p
                      >Email: <b>{{ initialValues.email }}</b></p
                    >
                  </v-col>
                  <v-col cols="1">
                    <p>Income Tax Reg. No:</p>
                  </v-col>
                  <v-col cols="2" class="pl-0 pr-6">
                    <v-text-field
                      class="select-style"
                      dense
                      placeholder="Add Income Tax Reg. No."
                      outlined
                      single-line
                      v-model="initialValues.incomeTaxRegNo"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <p
                      >Registration Address:
                      <b>{{ initialValues.registrationAddress }}</b></p
                    >
                  </v-col>
                  <v-col cols="1">
                    <p>VAT Status:</p>
                  </v-col>
                  <v-col cols="2">
                    <v-select
                      class="select-style"
                      dense
                      outlined
                      single-line
                      v-model="vatStatusData"
                      :items="vatStatusOptions"
                      item-text="label"
                      item-value="value"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
       <v-row class="justify-end">
        <v-col cols="12 d-flex flex-row-reverse">
          <v-btn
            v-if="isOpenTabForm === false"
            color="teal lighten-1 float-right"
            depressed
            dark
            @click="openTabForm"
          >
            <v-icon>mdi-credit-card-plus</v-icon>
            <span class="white--text ml-1">New Record</span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col :cols="layoutColumn">
          <v-card outlined>
            <template>
              <div>
                <v-tabs v-model="tab" centered color="red darken-4">
                  <v-tabs-slider color="red darken-4"></v-tabs-slider>
                  <v-tab
                    v-for="item in items"
                    :key="item"
                    @click="
                      item === 'Accounting'
                        ? getAllAccountingData()
                        : item === 'VAT Returns'
                        ? getVatReturnsData()
                        : item === 'VAT Recaps'
                        ? getVatRecapsData()
                        : item === 'Tax'
                        ? getAccountingTax()
                        : item === 'Employee'
                        ? getEmployeeData()
                        : getAllAccountingData()
                    "
                  >
                    {{ item }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item :key="items.Accounting">
                    <v-card flat>
                      <v-data-table
                        :headers="accountingDataheaders"
                        :items="accountingsData"
                        :hide-default-footer="false"
                        class="elevation-0"
                        outlined
                      >
                        <template v-slot:item.concluded="{ item }">
                          <v-simple-checkbox
                            v-model="item.concluded"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.audit="{ item }">
                          <v-simple-checkbox
                            v-model="item.audit"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.paidUnpaid="{ item }">
                          <v-simple-checkbox
                            v-model="item.paidUnpaid"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="updateAccoutingData(item)"
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Record</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="deleteAccountingDataItem(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Record</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="items.VatReturns">
                    <v-card flat>
                      <v-data-table
                        :headers="vatReturnsDataHeaders"
                        :items="vatReturnsData"
                        :hide-default-footer="false"
                        class="elevation-0"
                        outlined
                      >
                        <template v-slot:item.submitted="{ item }">
                          <v-simple-checkbox
                            v-model="item.submitted"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="updateVatReturnDataItem(item)"
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Record</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="deleteVatReturnDataItem(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Record</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="items.VATRecaps">
                    <v-card flat>
                      <v-data-table
                        :headers="vatRecapsDataHeaders"
                        :items="vatRecapsData"
                        :hide-default-footer="false"
                        class="elevation-0"
                        outlined
                      >
                        <template v-slot:item.submitted="{ item }">
                          <v-simple-checkbox
                            v-model="item.submitted"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="updateVatRecapDataItem(item)"
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Record</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="deleteVatRecapsDataItem(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Record</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="items.Tax">
                    <v-card flat>
                      <v-data-table
                        :headers="accouningTaxDataHeaders"
                        :items="taxData"
                        :hide-default-footer="false"
                        class="elevation-0"
                        outlined
                      >
                        <template v-slot:item.submitReturn="{ item }">
                          <v-simple-checkbox
                            v-model="item.submitReturn"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.refundSubmission="{ item }">
                          <v-simple-checkbox
                            v-model="item.refundSubmission"
                            disabled
                          ></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="updateTaxDataItem(item)"
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Record</span>
                          </v-tooltip>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="deleteTaxItem(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Record</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item :key="items.Employee">
                    <v-card flat>
                      <v-data-table
                        :headers="accountingEmployeesHeader"
                        :items="employeeData"
                        :hide-default-footer="false"
                        class="elevation-0"
                        outlined
                      >
                        <template v-slot:item.actions="{ item }">
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="updateAccoutingEmployeeData(item)"
                              >
                                <v-icon>mdi-pencil-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Edit Record</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                icon
                                v-on="on"
                                @click="deleteEmployeeItem(item)"
                              >
                                <v-icon>mdi-trash-can-outline</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete Record</span>
                          </v-tooltip>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </v-card>
        </v-col>
        <!-- Accounting Tab Add/update -->
        <AccountingDataForm
          :tab-name="tabName"
          :is-form="isOpenTabForm"
          :client-id="clientId"
          @closeTabForm="closeTabForm"
          @refreshAccounting="refreshTabData"
        />

        <EditAccountingDataForm
          :tab-name="tabName"
          :is-form="isOpenEditTabForm"
          :record-data="editRecordData"
          :edit-clientid="editClientid"
          @closeTabForm="closeEditTabForm"
          @refreshAccounting="refreshTabData"
        />

        <!-- VAT Return Tab Add/update -->
        <VatReturnDataForm
          :tab-name="tabName"
          :is-form="isOpenTabForm"
          :client-id="clientId"
          :client="vatType"
          @closeTabForm="closeTabForm"
          @refreshAccounting="refreshTabData"
          :period="vatReturnPeriodData"
        />

        <EditVatReturnData
          :tab-name="tabName"
          :is-form="isOpenEditTabForm"
          :record-data="editRecordData"
          :edit-clientid="editClientid"
          :client="vatType"
          @closeTabForm="closeEditTabForm"
          @refreshAccounting="refreshTabData"
          :period="vatReturnPeriodData"
        />

        <!-- VAT Recaps Tab Add/update -->
        <VatRecapDataForm
          :tab-name="tabName"
          :is-form="isOpenTabForm"
          :client-id="clientId"
          @closeTabForm="closeTabForm"
          @refreshAccounting="refreshTabData"
          :period="vatReturnPeriodData"
        />

        <EditVatRecapData
          :tab-name="tabName"
          :is-form="isOpenEditTabForm"
          :record-data="editRecordData"
          :edit-clientid="editClientid"
          @closeTabForm="closeEditTabForm"
          @refreshAccounting="refreshTabData"
          :period="vatReturnPeriodData"
        />

        <!-- Tax Tab Add/update -->
        <TaxDataForm
          :tab-name="tabName"
          :is-form="isOpenTabForm"
          :client-id="clientId"
          @closeTabForm="closeTabForm"
          @refreshAccounting="refreshTabData"
        />

        <EditTaxData
          :tab-name="tabName"
          :is-form="isOpenEditTabForm"
          :record-data="editRecordData"
          :edit-clientid="editClientid"
          @closeTabForm="closeEditTabForm"
          @refreshAccounting="refreshTabData"
        />

        <!-- Accounting Employee Add/update -->
        <AccountingEmployeeDataForm
          :tab-name="tabName"
          :is-form="isOpenTabForm"
          :client-id="clientId"
          @closeTabForm="closeTabForm"
          @refreshAccounting="refreshTabData"
        />

        <EditAccountingEmployeeData
          :tab-name="tabName"
          :is-form="isOpenEditTabForm"
          :record-data="editRecordData"
          :edit-clientid="editClientid"
          @closeTabForm="closeEditTabForm"
          @refreshAccounting="refreshTabData"
        />
      </v-row>
    </v-col>
  </v-row>
  <!-- </Layout> -->
</template>
<script>
import { validationMixin } from 'vuelidate'
// import Layout from '@layouts/main'
import SelfBuildingSpinner from '@components/Shared/SelfBuildingSpinner.vue'
import EventBus from '@utils/EventBus'
import api from '@src/api/index.js'
import { EventBusEvents } from '@src/constants/index.js'
import { validateError } from '@utils/validateFields'
import AccountingDataForm from '@components/AccountingData/AccountingDataForm.vue'
import EditAccountingDataForm from '@components/AccountingData/EditAccountingDataForm.vue'
import VatReturnDataForm from '@components/AccountingData/VatReturnDataForm.vue'
import EditVatReturnData from '@components/AccountingData/EditVatReturnData.vue'
import VatRecapDataForm from '@components/AccountingData/VatRecapDataForm.vue'
import EditVatRecapData from '@components/AccountingData/EditVatRecapData.vue'
import TaxDataForm from '@components/AccountingData/TaxDataForm.vue'
import EditTaxData from '@components/AccountingData/EditTaxData.vue'
import AccountingEmployeeDataForm from '@components/AccountingData/AccountingEmployeeDataForm.vue'
import EditAccountingEmployeeData from '@components/AccountingData/EditAccountingEmployeeData.vue'

export default {
  name: 'EditAccountingData',
  components: {
    SelfBuildingSpinner,
    // Layout,
    AccountingDataForm,
    EditAccountingDataForm,
    VatReturnDataForm,
    EditVatReturnData,
    VatRecapDataForm,
    EditVatRecapData,
    TaxDataForm,
    EditTaxData,
    AccountingEmployeeDataForm,
    EditAccountingEmployeeData,
  },
  mixins: [validationMixin],
  props: {
    accountingId: {
      type: Object,
      default: () => {},
    },
    clientId: {
      type: Number,
      default: -1,
    },
    itemData: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      vatReturnPeriodOptions: [
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Yearly', value: 'yearly' },
      ],
      vatStatusOptions: [
        { label: 'Registered Articled 10', value: 'Registered Articled 10' },
        { label: 'Registered Articled 11', value: 'Registered Articled 11' },
        { label: 'De-Registered', value: 'de-registered' },
      ],
      client_Id: '',
      clientName: '',
      clientType: '',
      documentId: '',
      fsStatementFrom: '',
      fsStatementTo: '',
      incomeTaxReturnFrom: '',
      incomeTaxReturnTo: '',
      ituRegistration: '',
      vatReturnFrom: '',
      vatReturnPaid: '',
      vatReturnPeriod: '',
      vatReturnTo: '',
      vatStatus: '',
      loading: false,
      formLoading: false,
      initialValues: {},
      updateValues: {},
      search: '',
      isSecondColumn: false,
      isOpenTabForm: false,
      isOpenEditTabForm: false,
      editRecordData: {},
      vatType: 'Monthly',
      tabName: 'Accounting',
      editClientid: 0,
      layoutColumn: 12,
      getActiveTab: 1,
      setActiveTab: 0,
      tab: null,
      vatReturnPeriodData: '',
      vatStatusData: '',
      status: '',
      registrationNumber: '',
      vatNumber: '',
      companyNumber: '',
      incorporationDate: '',
      email: '',
      registrationAddress: '',
      items: ['Accounting', 'VAT Returns', 'VAT Recaps', 'Tax', 'Employee'],
      accountingDataheaders: [
        // {
        //   text: 'Client Name',
        //   align: 'center',
        //   value: 'Client.Individual.clientName',
        // },
        { text: 'Financial Year', align: 'center', value: 'finanicialYear' },
        { text: 'Accounting Fees', value: 'accountingFees', align: 'center' },
        {
          text: 'VAT Compliance Fee',
          value: 'vatComplianceFees',
          align: 'center',
        },
        {
          text: 'Tax Compliance Fee',
          value: 'taxComplianceFees',
          align: 'center',
        },
        { text: 'Concluded', value: 'concluded', align: 'center' },
        { text: 'Audit', value: 'audit', align: 'center' },
        { text: 'Audit Fee', value: 'auditFees', align: 'center' },
        { text: 'Paid/Unpaid', value: 'paidUnpaid', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      vatReturnsDataHeaders: [
        // {
        //   text: 'Client Name',
        //   align: 'left',
        //   value: 'Client.Individual.clientName',
        // },
        { text: 'From', align: 'left', value: 'fromDate' },
        { text: 'To', value: 'toDate', align: 'center' },
        { text: 'Due Date', value: 'dueDate', align: 'center' },
        { text: 'Submitted', value: 'submitted', align: 'center' },
        { text: 'Amount Payable', value: 'amountPayable', align: 'center' },
        {
          text: 'Amount Receivable',
          value: 'amountReceivable',
          align: 'center',
        },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      vatRecapsDataHeaders: [
        // {
        //   text: 'Client Name',
        //   align: 'left',
        //   value: 'Client.Individual.clientName',
        // },
        { text: 'From', align: 'left', value: 'fromDate' },
        { text: 'To', value: 'toDate', align: 'center' },
        { text: 'Due Date', value: 'dueDate', align: 'center' },
        { text: 'Submitted', value: 'submitted', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      accouningTaxDataHeaders: [
        // {
        //   text: 'Client Name',
        //   align: 'center',
        //   value: 'Client.Individual.clientName',
        // },
        { text: 'Financial Year', align: 'center', value: 'finanicialYear' },
        {
          text: 'Year Of Assessment',
          value: 'yearOfAssessment',
          align: 'center',
        },
        { text: 'Submit Return', value: 'submitReturn', align: 'center' },
        {
          text: 'Refund Eligibility',
          value: 'refundEligibility',
          align: 'center',
        },
        {
          text: 'Refund Submission',
          value: 'refundSubmission',
          align: 'center',
        },
        { text: 'Refund Value', value: 'refundValue', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      accountingEmployeesHeader: [
        // {
        //   text: 'Client Name',
        //   align: 'center',
        //   value: 'Client.Individual.clientName',
        // },
        { text: 'Employee', align: 'left', value: 'employee' },
        { text: 'ID Card No', value: 'idCardNo', align: 'center' },
        { text: 'Tax Number', value: 'taxNumber', align: 'center' },
        {
          text: 'Social Security Number',
          value: 'socialSecurityNumber',
          align: 'center',
        },
        { text: 'Basic Wage', value: 'basicWage', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' },
      ],
      accountingsData: [],
      vatReturnsData: [],
      vatRecapsData: [],
      taxData: [],
      employeeData: [],
      accountings: [],
      getAllAccData: [],
      getClientAccountingData: [],
      employee: '',
      accountingID: 0,
      exportBtn: false,
      fileName:"accounting-data",
      accountingFields:{
	      "Client Name":"name",
        "Finanicial Year":"data.finanicialYear",
	      "Accounting Fees":"data.accountingFees",
        "Vat Compliance Fees":"data.vatComplianceFees",
	      "Tax Compliance Fees":"data.taxComplianceFees",
        "Concluded":"data.concluded",
	      "Audit":"data.audit",
        "Audit Fees":"data.auditFees",
	      "Paid & Unpaid":"data.paidUnpaid"
  },
    }
  },
  computed: {},
  created() {
    this.accountingID = this.accountingId.accountingId
    this.clientType = this.accountingId.clientType
    this.getAccountingByAccountingId(this.accountingID)
    this.getAllAccountings();
    this.getAllAccountingData();
  },
  methods: {
    resetAll() {
      this.$router.push({ name: 'accounting' })
    },
    requiredError(field, fieldName) {
      return validateError(field, ['required'], `${fieldName} is required`)
    },

    async getAccountingByAccountingId() {
      this.formLoading = true
      try {
        const accounting = await api.getAccountingByAccountingId(
          this.accountingID,
          this.clientType
        )
        const initialValues = {
          clientId: accounting.accounting_data.clientId
            ? accounting.accounting_data.clientId.toString()
            : '-',
          clientName: accounting.accounting_data.Client.Individual
            ? accounting.accounting_data.Client.Individual.clientName
            : accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.companyName
            : '-',
          // clientType: accounting.accounting_data.Client.clientType
          //   ? accounting.accounting_data.Client.clientType
          //   : 'Null',
          documentId: accounting.accounting_data.documentId
            ? accounting.accounting_data.documentId
            : '-',
          fsStatementFrom: accounting.accounting_data.fsStatementFrom
            ? accounting.accounting_data.fsStatementFrom
            : '-',
          fsStatementTo: accounting.accounting_data.fsStatementTo
            ? accounting.accounting_data.fsStatementTo
            : '-',
          incomeTaxReturnFrom: accounting.accounting_data.incomeTaxReturnFrom
            ? accounting.accounting_data.incomeTaxReturnFrom
            : '-',
          incomeTaxReturnTo: accounting.accounting_data.incomeTaxReturnTo
            ? accounting.accounting_data.incomeTaxReturnTo
            : '-',
          ituRegistration: accounting.accounting_data.ituRegistration
            ? accounting.accounting_data.ituRegistration
            : '-',
          vatReturnFrom: accounting.accounting_data.vatReturnFrom
            ? accounting.accounting_data.vatReturnFrom
            : '-',
          vatReturnPaid: accounting.accounting_data.vatReturnPaid
            ? accounting.accounting_data.vatReturnPaid
            : '-',
          vatReturnPeriod: accounting.accounting_data.vatReturnPeriod
            ? accounting.accounting_data.vatReturnPeriod
            : '-',
          vatReturnTo: accounting.accounting_data.vatReturnTo
            ? accounting.accounting_data.vatReturnTo
            : '-',
          vatStatus: accounting.accounting_data.vatStatus
            ? accounting.accounting_data.vatStatus
            : '-',
          status: accounting.accounting_data.Client.Individual
            ? accounting.accounting_data.Client.Individual.status
            : accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.status
            : '-',
          registrationNumber: accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.registrationNumber
            : '-',
          vatNumber: accounting.accounting_data.Client.Individual
            ? accounting.accounting_data.Client.Individual.vatNumber
            : accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.vatNumber
            : '-',
          email: accounting.accounting_data.Client.Individual
            ? accounting.accounting_data.Client.Individual.email
            : accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.email
            : '-',
          incorporationDate: accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.incorporationDate
            : '-',
          registrationAddress: accounting.accounting_data.Client.Corporate
            ? accounting.accounting_data.Client.Corporate.registrationAddress
            : '-',
          peNumber: accounting.accounting_data.peNumber
            ? accounting.accounting_data.peNumber
            : '',
          etcNumber: accounting.accounting_data.etcNumber
            ? accounting.accounting_data.etcNumber
            : '',
          incomeTaxRegNo: accounting.accounting_data.incomeTaxRegNo
            ? accounting.accounting_data.incomeTaxRegNo
            : '',
        }
        const updateValues = {
          clientId: accounting.accounting_data.clientId
            ? accounting.accounting_data.clientId.toString()
            : null,
          documentId: accounting.accounting_data.documentId
            ? accounting.accounting_data.documentId
            : null,
          fsStatementFrom: accounting.accounting_data.fsStatementFrom
            ? accounting.accounting_data.fsStatementFrom
            : null,
          fsStatementTo: accounting.accounting_data.fsStatementTo
            ? accounting.accounting_data.fsStatementTo
            : null,
          incomeTaxReturnFrom: accounting.accounting_data.incomeTaxReturnFrom
            ? accounting.accounting_data.incomeTaxReturnFrom
            : null,
          incomeTaxReturnTo: accounting.accounting_data.incomeTaxReturnTo
            ? accounting.accounting_data.incomeTaxReturnTo
            : null,
          ituRegistration: accounting.accounting_data.ituRegistration
            ? accounting.accounting_data.ituRegistration
            : null,
          vatReturnFrom: accounting.accounting_data.vatReturnFrom
            ? accounting.accounting_data.vatReturnFrom
            : null,
          vatReturnPaid: accounting.accounting_data.vatReturnPaid
            ? accounting.accounting_data.vatReturnPaid
            : null,
          vatReturnPeriod: accounting.accounting_data.vatReturnPeriod
            ? accounting.accounting_data.vatReturnPeriod
            : null,
          vatReturnTo: accounting.accounting_data.vatReturnTo
            ? accounting.accounting_data.vatReturnTo
            : null,
          vatStatus: accounting.accounting_data.vatStatus
            ? accounting.accounting_data.vatStatus
            : null,
        }
        this.vatReturnPeriodData = accounting.accounting_data.vatReturnPeriod
          ? accounting.accounting_data.vatReturnPeriod
          : '-'
        this.vatStatusData = accounting.accounting_data.vatStatus
          ? accounting.accounting_data.vatStatus
          : '-'
        this.initialValues = initialValues
        this.updateValues = updateValues
        this.vatType = this.vatReturnPeriodData
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.formLoading = false
      }
    },
    vatReturn({ vatReturnPaid }) {
      return vatReturnPaid ? 'Yes' : 'No'
    },
    openSecondColumn() {
      this.isSecondColumn = true
      this.layoutColumn = 4
    },
    openTabForm() {
      this.isOpenEditTabForm = false
      this.isOpenTabForm = true
      this.layoutColumn = 8
    },
    async getAllAccountingData() {
      this.tabName = 'Accounting'
      this.loading = true
      this.exportBtn = true
      try {
        const accountingData = await api.getAccountingData(this.clientId)
        this.accountingsData = accountingData.accounting_data
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getVatReturnsData() {
      this.tabName = 'VatReturns'
      this.loading = true
      this.exportBtn = false
      try {
        const vatReturnsData = await api.getVatReturnsData(this.clientId)
        this.vatReturnsData = vatReturnsData.vat_returns
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getVatRecapsData() {
      this.tabName = 'VatRecaps'
      this.loading = true
        this.exportBtn = false
      try {
        const VatRecapsData = await api.getVatRecapsData(this.clientId)
        this.vatRecapsData = VatRecapsData.vat_recaps
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getAccountingTax() {
      this.tabName = 'VatAccountingTax'
      this.loading = true
        this.exportBtn = false
      try {
        const TaxData = await api.getAccountingTax(this.clientId)
        this.taxData = TaxData.tax_data
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async getEmployeeData() {
      this.tabName = 'Employee'
      this.loading = true
        this.exportBtn = false
      try {
        const EmployeeData = await api.getAccountEmployeeData(this.clientId)
        this.employeeData = EmployeeData.accounting_employee
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteAccountingData(accountingId) {
      this.loading = true
      try {
        const data = await api.deleteAccountingData(accountingId)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.getAllAccountingData()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteVatReturnData(accountingId) {
      this.loading = true
      try {
        const data = await api.deleteVatReturnData(accountingId)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.getVatReturnsData()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteVatRecapsData(accountingId) {
      this.loading = true
      try {
        const data = await api.deleteVatRecapsData(accountingId)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.getVatRecapsData()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteTaxData(accountingId) {
      this.loading = true
      try {
        const data = await api.deleteAccountingTax(accountingId)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
        this.getAccountingTax()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    async deleteEmployeeData(accountingEmployeeId) {
      this.loading = true
      try {
        await api.deleteEmployeeData(accountingEmployeeId)
        this.getEmployeeData()
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
    closeTabForm() {
      this.isOpenTabForm = false
      if (!this.isSecondColumn && !this.isOpenEditTabForm) {
        this.layoutColumn = 12
      }
    },
    closeEditTabForm() {
      this.isOpenEditTabForm = false
      if (!this.isSecondColumn && !this.isOpenTabForm) {
        this.layoutColumn = 12
      }
    },
    updateAccoutingData(item) {
      this.editClientid = item.clientId
      this.isOpenEditTabForm = true
      this.layoutColumn = 4
      this.editRecordData = item
    },

    updateVatReturnDataItem(item) {
      this.editClientid = item.clientId
      this.isOpenEditTabForm = true
      this.layoutColumn = 4
      this.editRecordData = item
    },

    updateVatRecapDataItem(item) {
      this.editClientid = item.clientId
      this.isOpenEditTabForm = true
      this.layoutColumn = 4
      this.editRecordData = item
    },

    updateTaxDataItem(item) {
      this.editClientid = item.clientId
      this.isOpenEditTabForm = true
      this.layoutColumn = 4
      this.editRecordData = item
    },

    updateAccoutingEmployeeData(item) {
      this.editClientid = item.clientId
      this.isOpenEditTabForm = true
      this.layoutColumn = 4
      this.editRecordData = item
    },
    deleteAccountingDataItem({ accountingId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete Accounting Data',
        message: 'Are you sure to delete this accounting data?',
        confirmAction: () => this.deleteAccountingData(accountingId),
      })
    },
    deleteVatReturnDataItem({ AccountingVatReturnsId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete VAT Return',
        message: 'Are you sure to delete this VAT Returns data?',
        confirmAction: () => this.deleteVatReturnData(AccountingVatReturnsId),
      })
    },
    deleteVatRecapsDataItem({ accountingVatRecapsId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete VAT Recaps',
        message: 'Are you sure to delete this VAT Recaps data?',
        confirmAction: () => this.deleteVatRecapsData(accountingVatRecapsId),
      })
    },
    deleteTaxItem({ accountingTaxId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete Tax Record',
        message: 'Are you sure to delete this Tax data?',
        confirmAction: () => this.deleteTaxData(accountingTaxId),
      })
    },
    deleteEmployeeItem({ accountingEmployeeId }) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Delete Employee Record',
        message: 'Are you sure to delete this Employee data?',
        confirmAction: () => this.deleteEmployeeData(accountingEmployeeId),
      })
    },
    resetFormData() {
      this.clientId = null
      this.accountingEmployeeId = null
      this.$refs.formUserEl.reset()
    },
    refreshTabData() {
      if (this.tabName === 'Accounting') {
        this.getAllAccountingData()
      } else if (this.tabName === 'VatReturns') {
        this.getVatReturnsData()
      } else if (this.tabName === 'VatRecaps') {
        this.getVatRecapsData()
      } else if (this.tabName === 'VatAccountingTax') {
        this.getAccountingTax()
      } else if (this.tabName === 'Employee') {
        this.getEmployeeData()
      }
    },
    async updateData() {
      const payload = {
        accountingId: this.accountingID,
        clientId: this.updateValues.clientId,
        incomeTaxRegNo: this.initialValues.incomeTaxRegNo,
        peNumber: this.initialValues.peNumber,
        etcNumber: this.initialValues.etcNumber,
        vatStatus: this.vatStatusData,
        vatReturnPeriod: this.vatReturnPeriodData,
      }
      this.loading = true
      try {
        const data = await api.updateAccountData({ payload })
        this.loading = false
        this.getAccountingByAccountingId(this.accountingID)
        EventBus.$emit(EventBusEvents.SNACKBAR_SUCCESS, data, this)
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    save() {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        show: true,
        title: 'Update Account',
        message: 'Are you sure to update accounting data?',
        confirmAction: () => this.updateData(),
      })
    },
    getVatValue(data) {
      this.vatType = data
    },
    async getAllAccountings() {
      this.loading = true
      try {
        const accountings = await api.getAllAccountings()
        this.accountings = accountings;
      } catch (err) {
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style>
/* .col-border {
  border: thin solid rgba(0, 0, 0, 0.12);
}
.max-width1 {
  width: 30%;
}
.client-size {
  margin-left: -12px;
  margin-top: -12px;
}
.text-size {
  height: 20px;
  margin: 5px 50px 5px -5px;
}
.color-red {
  color: red;
}
.row-margin {
  margin-bottom: -20px;
}
.row-col-height {
  height: 50px;
}
.row-left-margin {
  margin-left: 5px;
}
.col-margin {
  margin-left: -60px;
}
.col-margin-bottom {
  margin-bottom: 1px;
}
.select-style {
  max-width: 140px;
  margin-top: -5px !important;
  font-size: 12px;
}
.select-style .v-input__slot {
  min-height: 30px !important;
}
.select-style .v-input__slot .v-input__append-inner {
  margin-top: 0px !important;
}
.select-style .v-input__slot .v-icon {
  background-color: #ddd !important;
  border-radius: 12px !important;
  font-size: 16px;
  margin-top: 6px;
  margin-left: 10px;
}
.dropdown-margin {
  margin-left: -60px;
}
.save-button-margin {
  margin-right: 10px;
} */
</style>
